<template>
  <div class="flex-container non-scrollable">
    <page-title value="Remboursements notes de frais"/>
    <div class="scrollable">
      <DataTable ref="dt" editMode="row" :value="localNDFS.filter(n => n.adminStatus === constants.NDF_ADMIN_STATUS.REFUND || !showOnlyNDFToRefund)"
                 :paginator="true" class="p-datatable-sm editable-cells-table" :rows="constants.defaultRowNumber"
                 selection-mode="single" dataKey="id" :rowHover="false" v-model:filters="filters" v-model:editingRows="editingRows"
                 filterDisplay="menu" :loading="loading" scrollable scroll-height="flex"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.ndf')})"
                 :rowsPerPageOptions="constants.rowsPerPageOptions" @row-edit-save="onRowEditSave" @row-click="clickNDF">
        <template #header>
          <div class="flex justify-content-between">
            <div class="p-d-flex flex align-items-center gap-2">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
              </span>
              <CalendarRange auto-first-update @update:model-value="loadNDFS"/>
              <div class="flex align-items-center">
                <Checkbox class="mr-2" v-model="showOnlyNDFToRefund" :binary="true"></Checkbox>
                <span>N'afficher que les notes de frais à rembourser</span>
              </div>
            </div>
            <div class="flex flex-row gap-2">
              <Button :disabled="ndfs.length === 0" :class="{ 'disabled-button': ndfs.length === 0 }" :loading="refundLoading" icon="pi pi-money-bill" :label="$t('refundAll')" @click="refundAll" />
              <Button :disabled="ndfs.length === 0" :class="{ 'disabled-button': ndfs.length === 0 }" :loading="exportSheetLoading" icon="pi pi-external-link" label="Exporter les résultats" @click="toggleMenu" />
              <Button :disabled="ndfs.length === 0" :class="{ 'disabled-button': ndfs.length === 0 }" :loading="exportSheetLoading" icon="pi pi-external-link" label="Tout exporter" @click="(event) => toggleMenu(event, true)" />
              <Menu ref="menu" :model="exportTypes" :popup="true" />
            </div>
          </div>

        </template>
        <template #empty>
          {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
        </template>
        <template #loading>
          {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
        </template>
<!--        <Column selectionMode="multiple" headerStyle="width: 3rem"/>-->
        <!--      filterField="invoice_number" :showFilterMatchModes="false"-->
<!--        <Column field="noNdf" :header="$t('numeroNDF').toUpperCase()" sortable sortField="noNdf" class="non-clickable-column">-->
<!--          <template #body="{data, field}">-->
<!--            <div @click="clickNDF(data)">{{data[field]}}</div>-->
<!--          </template>-->
<!--        </Column>-->
        <Column field="independent.last_name" :header="$t('lastname').toUpperCase()" sortable filterField="independent.last_name" sortField="independent.last_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('independent.last_name')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="independent.first_name" :header="$t('firstname').toUpperCase()" sortable filterField="independent.first_name" sortField="independent.first_name" :showFilterMatchModes="false">
          <template #filter="{filterModel}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
            <MultiSelect v-model="filterModel.value" :options="commonFilter('independent.first_name')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="independent.cagnotte" :header="$t('cagnotte').toUpperCase()" sortable sortField="independent.cagnotte">
          <template #body="{data}">
            <span class="font-bold" :style="data.independent.cagnotte >= 0 ? 'color: green' : 'color: red'">{{ getEuroFormatFromEuro(data.independent.cagnotte / 100) }}</span>
          </template>
        </Column>
        <Column field="year" :header="$t('year').toUpperCase()" sortable filterField="year" sortField="year" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('year') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('year')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="month" :header="$t('month').toUpperCase()" sortable filterField="month" sortField="month" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data}">
            <span class="uppercase">{{data.month}}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('month') }}</div>
            <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="commonFilter('month')" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
              <template #option="slotProps">
                <div>
                  <span>{{slotProps.option.value}}</span>
                </div>
              </template>
            </MultiSelect>
          </template>
        </Column>
        <Column field="type" :header="$t('category').toUpperCase()" sortable filterField="type" sortField="type" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data, field}">
            {{ getOptionLabel(constants.data.ndfTypeOptions, data[field]) }}
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('category') }}</div>
            <MultiSelect v-model="filterModel.value" :options="typeOptions" option-value="value" optionLabel="label"
                         :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem"
                         @change="filterCallback()">
            </MultiSelect>
          </template>
        </Column>
        <Column field="nbDepenses" :header="$t('nbDepenses').toUpperCase()" sortable sortField="nbDepenses">
        </Column>
        <Column field="ttc" :header="$t('ttc').toUpperCase()" sortable sortField="ttc">
          <template #body="{data, field}">
            {{ getEuroFormatFromEuro(data[field] / 100) }}
          </template>
        </Column>
        <Column field="tvaRecuperee" :header="$t('tvaRecuperee').toUpperCase()" sortable sortField="tvaRecuperee">
          <template #body="{data, field}">
            {{ data.type === constants.NDF_TYPE.NDF ? getEuroFormatFromEuro(data[field] / 100) : ''}}
          </template>
        </Column>
        <Column field="remboursement" :header="$t('remboursement').toUpperCase()" :sortable="false" filterField="remboursement">
          <template #body="{data}">
            <Checkbox :binary="true" v-model="data.remboursement" @change="refundNdf(data)"></Checkbox>
          </template>
        </Column>
        <Column field="dateValidation" :header="$t('dateValidation').toUpperCase()" sortable filterField="dateValidation" sortField="dateValidation" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data, field}">
          <span>{{data[field]?.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })}}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('dateValidation') }}</div>
            <Calendar @date-select="filterCallback()" v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="mm/dd/yyyy" />
          </template>
        </Column>
        <Column field="dateRemboursement" :header="$t('dateRemboursement').toUpperCase()" sortable filterField="dateRemboursement" sortField="dateValidation" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
          <template #body="{data, field}">
          <span>{{data[field] != null ? data[field]?.toLocaleDateString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }) : ''}}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <div class="p-mb-3 p-text-bold mb-3">{{ $t('dateRemboursement') }}</div>
            <Calendar @date-select="filterCallback()" v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="mm/dd/yyyy" />
          </template>
        </Column>
        <Column field="comment" :header="$t('comment').toUpperCase()" sortField="comment">
          <template #editor="{data, field}">
            <InputText v-model="data[field]" autofocus></InputText>
          </template>
        </Column>
        <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
        <Column style="width:10%; min-width:8rem" bodyStyle="text-align:center">
          <template #body="{data}">
            <Button style="width: 30px; height: 30px;" class="p-button-rounded" icon="pi pi-undo" @click="cancelNdf(data)" v-if="data.mission === null" v-tooltip.top="'Annuler la note de frais'"></Button>
          </template>
        </Column>
      </DataTable>
    </div>
    <dialog-n-d-f-admin @reload="$emit('reload')" :key="keyDialog" :dialog="dialogNdf" :ndf="selectedNdf" @close="dialogNdf = false"></dialog-n-d-f-admin>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import {mapGetters, mapState} from 'vuex'
import { getEuroFormatFromEuro } from '@/utils/Money'
import SheetGenerator from "@/utils/SheetGenerator";
import Alert from "@/utils/Alert";
import DialogNDFAdmin from "@/components/NDF/DialogNDFAdmin";
import PageTitle from "@/components/PageTitle.vue";
import {getOptionLabel} from "@/utils/Misc";
import CalendarRange from "@/components/CalendarRange.vue";

export default {
  name: "NDFRemboursements",
  components: {CalendarRange, PageTitle, DialogNDFAdmin},
  data() {
    return {
      showOnlyNDFToRefund: true,
      keyDialog: 0,
      dialogNdf: false,
      selectedNdf: null,
      exportSheetLoading: false,
      refundLoading: false,
      lastRangeSaved: null,
      filters: {
        'global': {value: null, matchMode: null},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'year': {value: null, matchMode: FilterMatchMode.IN},
        'month': {value: null, matchMode: FilterMatchMode.IN},
        'type': {value: null, matchMode: FilterMatchMode.IN},
        'nbDepenses': {value: null, matchMode: FilterMatchMode.IN},
        'adminStatus': {value: null, matchMode: FilterMatchMode.IN},
        'remboursement': {value: null, matchMode: FilterMatchMode.IN},
        'ttc': {value: null, matchMode: FilterMatchMode.BETWEEN},
      },
      loading: true,
      editingRows: [],
      localNDFS: [],
      minTtc: 0,
      maxTtc: 999999,
      exportAll: false,
      itemsSelected: [],
      exportTypes: [
        {
          label: 'Modèle virement',
          command: () => {
            this.createSheetVirement()
          }
        },
        {
          label: 'Modèle comptabilité',
          command: () => {
            this.createSheetCompta()
          }
        },
      ],
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
    ...mapGetters({
      ndfs: 'ndf/getNDFS'
    }),
    typeOptions () {
      let array = this.ndfs.map((p) => {
        return {
          label: getOptionLabel(this.constants.data.ndfTypeOptions, p.type),
          value: p.type
        }
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item.value)) {
          filterArray.push(item)
        }
      })
      return filterArray
    },
    getImportSheetExtraProps () {
      return {
        adminStatus: !this.showOnlyNDFToRefund ? this.constants.NDF_ADMIN_STATUS.REFUNDED : this.constants.NDF_ADMIN_STATUS.REFUND
      }
    }
  },
  mounted() {
    this.loading = false;
  },
  methods: {
    getOptionLabel,
    toggleMenu (event, forAll = false) {
      this.exportAll = forAll
      this.$refs.menu.toggle(event)
    },
    async cancelNdf (ndf) {
      if (ndf.mission === null) {
        ndf.adminStatus = 'validate'
        ndf.dateRemboursement = null
        ndf.dateValidation = null
        await this.saveNDF(ndf, true)
        this.loadNDFS()
      }
    },
    async createSheetCompta () {
      this.exportSheetLoading = true
      // get directly the filtered and sorted data in the dataTable
      const ids = await this.$refs.dt.processedData.map(ndf => ndf.id)
      SheetGenerator({ ids, exportAll: this.exportAll }, `/ndf/export-sheet-compta?range=${JSON.stringify(this.lastRangeSaved)}`, this, this.getImportSheetExtraProps)
          .then(() => {
            this.exportSheetLoading = false
          })
          .catch(() => {
            this.exportSheetLoading = false
          })
    },
    async createSheetVirement () {
      this.exportSheetLoading = true
      // get directly the filtered and sorted data in the dataTable
      const ids = await this.$refs.dt.processedData.map(ndf => ndf.id)
      SheetGenerator({ ids, exportAll: this.exportAll }, `/ndf/export-sheet-virement?range=${JSON.stringify(this.lastRangeSaved)}`, this, this.getImportSheetExtraProps)
          .then(() => {
            this.exportSheetLoading = false
          })
          .catch(() => {
            this.exportSheetLoading = false
          })
    },
    getEuroFormatFromEuro (price, withUnit) {
      return getEuroFormatFromEuro(price, withUnit)
    },
    loadNDFS (range) {
      this.$store.state.misc.loading = true
      this.$store.dispatch('ndf/getAllNDFS', { range }).then(() => {
        this.localNDFS = this.ndfs.filter(ndf => ndf.adminStatus !== 'validate')
        this.lastRangeSaved = range
        this.$store.state.misc.loading = false
      })
    },
    compareDate (invoice) {
      let date = new Date()
      if (date > invoice.settlementPeriod) {
        const diffTime = Math.abs(date - invoice.settlementPeriod);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays + ' jours'
      }
      return ''
    },
    getFilterValue (value) {
      if (value.value && value.value.length) {
        if (value.value.length === 1) return value.value[0] ? 'Oui' : 'Non'
        let string = ''
        string += value.value[0] ? 'Oui' : 'Non'
        string += ', '
        string += value.value[1] ? 'Oui' : 'Non'
        return string
      } else {
        return value.placeholder
      }
    },
    commonFilter (field) {
      // console.log('commonFilter', field)
      let array = this.ndfs.map((s) => {
        let fields = field.split('.')
        let v = s
        fields.forEach((f) => {
          v = v[f]
        })
        return v
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item || Math.abs(fa.value - item) === 0)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    async refundAll () {
      this.$store.state.misc.loading = true
      this.refundLoading = true
      for (let ndf of this.localNDFS) {
        if (!ndf.remboursement) await this.refundNdf(ndf, false)
        ndf.remboursement = ndf.adminStatus === 'refunded'
      }
      this.refundLoading = false
      this.$store.state.misc.loading = false
    },
    async refundNdf (ndf, showAlert = true) {
      if (ndf.dateRemboursement == null) {
        ndf.adminStatus = 'refunded'
        ndf.dateRemboursement = new Date()
      } else {
        ndf.adminStatus = 'refund'
        ndf.dateRemboursement = null
      }
      await this.saveNDF(ndf, showAlert)
    },
    async saveNDF(ndf, showAlert = true) {
      let ndfToSave = {
        id: ndf.id,
        comment: ndf.comment,
        adminStatus: ndf.adminStatus,
        dateRemboursement: ndf.dateRemboursement
      }

      if (showAlert) this.$store.state.misc.loading = true

      await this.$store.dispatch('ndf/updateNDF', ndfToSave)
      if (showAlert) this.$store.state.misc.loading = false
      if (showAlert) Alert.successMessage(this, 'saveNdf')
    },
    onRowEditSave(event) {
      let { newData, index } = event;

      this.localNDFS[index] = newData;

      this.saveNDF(this.localNDFS[index])
    },
    async clickNDF(ndf) {
      this.selectedNdf = ndf.data
      this.dialogNdf = true
      this.keyDialog++
    }
  }
}
</script>

<style scoped>
.p-datatable {
  font-size: 0.85rem;
}
</style>
